import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';
import numberWithCommas from '../../../../../utils/numberToComma';

const HWMClubHouseCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12} direction="column" style={{ paddingTop: '3%' }}>
        <Typography className={classes.textHeader1}>Demand Assumption</Typography>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmClubhouse__pi_userLpcdPerOccupant)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_userLpcdPerOccupant)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userLpcdPerOccupant, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userLpcdPerOccupant, getQuantityDefaultValue(schema.hwmClubhouse__pi_userLpcdPerOccupant));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userLpcdPerOccupant)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmClubhouse__pi_capexSourceType) === 'vendor' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmClubhouse__pi_capexSourceType, null);
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmClubhouse__pi_capexSourceType, 'vendor');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.hwmClubhouse_hwmClubhouseVendorCapex))}
                {getQuantityUnit(schema.hwmClubhouse_hwmClubhouseVendorCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmClubhouse__pi_capexSourceType) === 'custom' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmClubhouse__pi_capexSourceType, null);
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmClubhouse__pi_capexSourceType, 'custom');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() => updateQuantityValue(schema.hwmClubhouse__pi_customCapex, getQuantityDefaultValue(schema.hwmClubhouse__pi_customCapex))}
                disabled={!getQuantityValue(schema.hwmClubhouse__pi_capexSourceType) === 'custom' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6"> {getQuantityUnit(schema.hwmClubhouse__pi_customCapex)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Project Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes may affect other project or intervention values</Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>{getQuantityLabel(schema.hwmClubhouse__pi_userCityResidentialUtilityTariff)}</Typography>
      <Grid item xs={4} direction="column" style={{ paddingTop: '10px' }}>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.hwmClubhouse__pi_userCityResidentialUtilityTariff)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.hwmClubhouse__pi_userCityResidentialUtilityTariff, e.target.value);
              }}
              fullWidth
              required
              onClick={(e) => {
                e.preventDefault();
                updateQuantityValue(
                  schema.hwmClubhouse__pi_userCityResidentialUtilityTariff,
                  getQuantityDefaultValue(schema.hwmClubhouse__pi_userCityResidentialUtilityTariff)
                );
              }}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userCityResidentialUtilityTariff)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmClubhouse__pi_userRooftopAreaPerSwh)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_userRooftopAreaPerSwh)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userRooftopAreaPerSwh, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userRooftopAreaPerSwh, getQuantityDefaultValue(schema.hwmClubhouse__pi_userRooftopAreaPerSwh));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userRooftopAreaPerSwh)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmClubhouse__pi_userRooftopAreaPerHp)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_userRooftopAreaPerHp)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userRooftopAreaPerHp, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userRooftopAreaPerHp, getQuantityDefaultValue(schema.hwmClubhouse__pi_userRooftopAreaPerHp));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userRooftopAreaPerHp)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmClubhouse__pi_userHotWaterHours)}</Typography>
            <CustomizedTooltips
              icon="info"
              title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
              placement="right"
            />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_userHotWaterHours)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userHotWaterHours, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userHotWaterHours, getQuantityDefaultValue(schema.hwmClubhouse__pi_userHotWaterHours));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userHotWaterHours)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmClubhouse__pi_userNumberOfShafts)}</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Used for calculating heat loss from standby water</Typography>} placement="right" />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_userNumberOfShafts)}
                placeholder=""
                onChange={(e) => {
                  updateQuantityValue(schema.hwmClubhouse__pi_userNumberOfShafts, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userNumberOfShafts, getQuantityDefaultValue(schema.hwmClubhouse__pi_userNumberOfShafts));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userNumberOfShafts)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmClubhouse__pi_userPoolAreaPerOccupant)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmClubhouse__pi_userPoolAreaPerOccupant)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmClubhouse__pi_userPoolAreaPerOccupant, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(
                    schema.hwmClubhouse__pi_userPoolAreaPerOccupant,
                    getQuantityDefaultValue(schema.hwmClubhouse__pi_userPoolAreaPerOccupant)
                  );
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userPoolAreaPerOccupant)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmClubhouse_cityEmissionFactor)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.hwmClubhouse_cityEmissionFactor))} kg CO2e/kWh
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};
export default HWMClubHouseCustomAdvancedParameters;
