import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApiRequest from '../../../../hooks/useApiRequest';
import axios from 'axios';
import { FeatureSchemaFinalised } from '../main/kong/FeatureSchemaFinalised';
import KongInterventionsComponentsMapper from '../main/kong/KongInterventions';
import { LegalQuantityTypes } from '../../../../../metafire/utils/types';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';

const InterventionDetailsContextData = createContext(null);

export const useInterventionDetailsContext = () => {
  const context = useContext(InterventionDetailsContextData);
  if (!context) {
    throw new Error('useInterventionDetailsContext should be used inside InterventionDetailsContext');
  }
  return context;
};

const InterventionDetailsContext = ({ children }) => {
  const params = useParams();
  const { getLabel, getUnit, getValue, getRoundOffValue, getDecimalPlacesByTag } = useAppMetafireUtilities();
  const [intervention, setIntervention] = useState(null);

  const [activeSandbox, setActiveSandbox] = useState(null);
  const [allSandboxes, setAllSandboxes] = useState(null);

  const {
    data: interventionResponse,
    isError: isErrorFetchingInterventionDetail,
    isFetching: isFetchingInterventionDetail,
  } = useAppApiRequest(`/projectv2/${params?.id}/interventions/${params?.iid}`, { method: 'GET' }, [params?.id, params?.iid], true);

  const [{ status: activeSandboxStatus, response: activeSandboxResponse }, getActiveSandbox] = useApiRequest(
    `/projectv2/${params?.id}/interventions/${params?.iid}/sandboxes?active=true`
  );
  // Seems like All Sandboxes is longer in use: interventionDetails/common/Header ?
  const [{ status: allSandboxStatus, response: allSandboxResponse }, getAllSandboxes] = useApiRequest(
    `/projectv2/${params?.id}/interventions/${params?.iid}/sandboxes`
  );

  const {
    data: metafireResponse,
    isError: isErrorInterventionFromMetafire,
    isFetching: isFetchingInterventionFromMetafire,
    refetch: getMetafire,
  } = useAppApiRequest(
    `/metafire?projectId=${params?.id}&feature=${intervention?.feature}`,
    { method: 'GET' },
    [params?.id, params?.iid],
    intervention?.feature !== undefined
  );

  const [{ status: projectInterventionMappingStatus, response: projectInterventionMappingResponse }, getProjectInterventionMapping] = useApiRequest(
    `/ProjectInterventionMapping/${params?.iid}`
  );

  /*
    A intervention schema should be verified and served,
    Ex schema: https://github.com/SmarterDharma/sdplus-calculess-nodejs/blob/alpha/schemas/intervention-schema.json
  */
  const schema = useMemo(() => {
    const selectedSchema = FeatureSchemaFinalised[intervention?.feature];
    if (selectedSchema) {
      return selectedSchema.reduce((memo, detail) => {
        memo[detail.id] = { ...detail };
        return memo;
      }, {});
    }
    return {};
  }, [intervention]);

  const getQuantitiesByArray = (data = {}) => {
    return Object.entries(data).map(([key, value]) => ({ id: key, ...value }));
  };

  const getWriteableQuantitiesForSandboxByArray = (data = {}) => {
    return Object.entries(data).reduce((memo, [key, obj]) => {
      const { rw, value, type, tags } = obj;
      // Only include values that are writeable(rw === 'w') and tag does not include project-input values IF they are writeable
      if (rw === 'w' && !tags.includes('project-input')) {
        switch (type) {
          case LegalQuantityTypes.UserActualDefault:
            memo.push({ id: key, value: value?.user });
            break;
          default:
            memo.push({ id: key, value });
            break;
        }
      }
      return memo;
    }, []);
  };

  const getWriteableQuantitiesProjectPIPMappingByArray = (data = {}) => {
    return Object.entries(data).reduce((memo, [key, obj]) => {
      const { rw, value, type, tags } = obj;
      // Only include values that are writeable(rw === 'w') and tag includes project-input values IF they are writeable
      if (rw === 'w' && tags.includes('project-input')) {
        switch (type) {
          case LegalQuantityTypes.UserActualDefault:
            memo.push({ PIPScheme: key, value: value?.user });
            break;
          default:
            memo.push({ PIPScheme: key, value });
            break;
        }
      }
      return memo;
    }, []);
  };

  const fetchAndUpdateDataContext = async () => {
    getMetafire();
    getActiveSandbox();
    getProjectInterventionMapping();
    // getAllSandboxes(); // TODO: May not be needed
  };

  const updateSandbox = async (data) => {
    const body = {
      type: 'dirty',
      values: getWriteableQuantitiesForSandboxByArray(data),
    };
    const activeSandboxId = activeSandbox[0]?._id;
    if (activeSandboxId) {
      await axios
        .put(`/projectv2/${params?.id}/interventions/${params?.iid}/sandboxes/${activeSandboxId}`, body)
        .then((res) => {
          console.log(body, 'HEY! SANDBOX UPDATED');
        })
        .catch((err) => console.log('Sandbox update error:', err));
      await fetchAndUpdateDataContext();
    }
  };

  const updateProjectPIPMapping = async (data) => {
    const body = {
      projectId: params?.id,
      values: getWriteableQuantitiesProjectPIPMappingByArray(data),
    };
    return axios
      .put(`/ProjectPIPMapping/handleProjectPIPmappingsByPIPSchemeNames`, body)
      .then((res) => {
        console.log(body, 'HEY! PROJECT PIP MAPPING UPDATED');
      })
      .catch((err) => console.log('Project PIP Mapping update error:', err));
  };

  const triggerSandoxUpdate = async () => {
    await updateProjectPIPMapping(quantities?.updated);
    await updateSandbox(quantities?.updated);
  };

  const [quantities, dispatch] = useReducer(
    function (state, action) {
      switch (action.type) {
        case 'initial-quantity-state': {
          return {
            ...state,
            updated: action.payload,
            initial: action.payload,
          };
        }
        case 'update-quantity-state': {
          const updateItem = state.updated[action.payload.id];
          if (updateItem) {
            const updatedQuantities = { ...state.updated, [action.payload.id]: { ...updateItem, value: action.payload.value } };
            return {
              ...state,
              updated: updatedQuantities,
            };
          }
          return state;
        }
        case 'reset-quantity-state': {
          return {
            ...state,
            updated: state.initial,
          };
        }
        case 'update-sandbox-state': {
          (async () => {
            await updateProjectPIPMapping(state.updated);
            await updateSandbox(state.updated);
          })();
          return { ...state };
        }
      }
    },
    { updated: {}, initial: {} }
  );

  useEffect(() => {
    if (interventionResponse) {
      setIntervention(interventionResponse?.data?.data?.data?.intervention);
    }
  }, [interventionResponse]);

  useEffect(() => {
    if (intervention) {
      getActiveSandbox();
      getProjectInterventionMapping();
    }
  }, [intervention]);

  useEffect(() => {
    if (allSandboxResponse) {
      setAllSandboxes(allSandboxResponse.data.data.data);
    }
  }, [allSandboxResponse]);

  useEffect(() => {
    if (activeSandboxResponse) {
      setActiveSandbox(activeSandboxResponse.data.data.data);
    }
  }, [activeSandboxResponse]);

  useEffect(() => {
    if (metafireResponse) {
      dispatch({
        type: 'initial-quantity-state',
        payload: metafireResponse?.data?.data?.data?.reduce((memo, detail) => {
          memo[detail.id] = { ...detail };
          return memo;
        }, {}),
      });
    }
  }, [metafireResponse]);

  const allmetaquantities = useMemo(() => {
    if (metafireResponse) {
      return metafireResponse?.data?.data?.data;
    }
    return [];
  }, [metafireResponse]);

  /*
    Compares each key:value pair between two objects,
    and for every updated value the respective key is assigned the it's value
    else the key's value is set to null
  */
  const extractUpdatedValues = (updatedValues, inititalValues) => {
    return Object.entries(updatedValues).reduce((memo, [key, value]) => {
      if (value === inititalValues[key]) {
        memo[key] = null;
      } else {
        memo[key] = value;
      }
      return memo;
    }, {});
  };

  const getMockQuantityValue = (value) => {
    return value;
  };

  const getQuantitiesByTypeDefinition = (type) => {
    return getQuantitiesByArray(quantities?.updated).filter((item) => item?.type === type);
  };

  const getQuantityLabel = (schemaObject) => {
    if (schemaObject) {
      const stateItem = quantities.updated[schemaObject.id];
      return getLabel(stateItem);
    }
    return '-';
  };

  const getQuantityUnit = (schemaObject) => {
    if (schemaObject) {
      const stateItem = quantities.updated[schemaObject.id];
      return getUnit(stateItem);
    }
    return '-';
  };

  const getQuantityValue = (schemaObject, state = quantities.updated) => {
    if (schemaObject) {
      const stateItem = state[schemaObject.id];
      return getValue(stateItem);
    }
    return '-';
  };

  const getQuantityDefaultValue = (schemaObject, state = quantities?.updated) => {
    if (schemaObject) {
      const stateItem = state[schemaObject.id];
      if (stateItem && schemaObject.type === LegalQuantityTypes.UserActualDefault) {
        return getRoundOffValue(stateItem?.value?.default);
      }
    }
    return '-';
  };

  const getQuantityActualValue = (schemaObject, state = quantities.updated) => {
    if (schemaObject) {
      const stateItem = state[schemaObject.id];
      if (stateItem && schemaObject.type === LegalQuantityTypes.UserActualDefault) {
        return getRoundOffValue(stateItem?.value?.actual);
      }
    }
    return '-';
  };

  const getValuesFromQuantityDetails = (value, key = 'tags') => {
    return allmetaquantities.reduce((memo, quantityItem) => {
      if (quantityItem[key].includes(value)) {
        const schemaObject = schema[quantityItem?.id];
        const quantitiesObject = { [quantityItem?.id]: quantityItem };

        if (schemaObject) {
          const valueObject = { ...quantityItem };
          switch (schemaObject?.type) {
            case LegalQuantityTypes.UserActualDefault:
              valueObject.value = getQuantityActualValue(schemaObject, quantitiesObject);
              break;
            default:
              valueObject.value = getQuantityValue(schemaObject, quantitiesObject);
              break;
          }
          memo.push(valueObject);
        }
      }
      return memo;
    }, []);
  };

  const appendChanged = (id, value) => dispatch({ type: 'update-quantity-state', payload: { id, value } });
  const updateQuantityValue = (schemaObject, value) => {
    if (schemaObject) {
      const stateItem = quantities.updated[schemaObject.id];
      if (
        stateItem &&
        schemaObject['rw'] === 'w' // Should be able to udpate a quanity value only if it is writeable
      ) {
        switch (schemaObject.type) {
          case LegalQuantityTypes.Number:
            const numberValue = value ? parseFloat(value) : value;
            return appendChanged(schemaObject.id, numberValue);
          case LegalQuantityTypes.UserActualDefault:
            const userUpdatedValue = isNaN(value) || String(value).endsWith('.') ? value : value === '' ? Number(0) : value === null ? null : value;
            const isReset = stateItem?.value?.default === userUpdatedValue;
            return appendChanged(schemaObject.id, { ...stateItem.value, user: isReset ? null : userUpdatedValue });
          case LegalQuantityTypes.String:
            const stringValue = value === null ? null : String(value);
            return appendChanged(schemaObject.id, stringValue);
          default:
            return appendChanged(schemaObject.id, value);
        }
      }
    }
  };

  const resetQuantityValues = () => {
    console.log('HEY! SANDBOX RESET');
    return dispatch({ type: 'reset-quantity-state', payload: null });
  };

  const featureKey = useMemo(() => {
    if (intervention) {
      return intervention?.feature;
    }
  }, [intervention]);

  const getShortlistedProductsForCombination = (products = []) => {
    if (featureKey) {
      const filterProducts = KongInterventionsComponentsMapper[featureKey]?.filterProducts;
      const shortlistedProducts = filterProducts({ products, schema, get: { getQuantityValue } });
      return shortlistedProducts;
    } else {
      return products;
    }
  };

  const getCategorySubCategoryAndFilterForCombination = (product = {}) => {
    if (featureKey && product) {
      const productSubcategoryAndFilter = KongInterventionsComponentsMapper[featureKey]?.productSubcategoryAndFilter;
      const URLdata = productSubcategoryAndFilter({ product });
      return URLdata;
    } else {
      return {};
    }
  };

  const getQuantitySchemaByProductType = (type = 'default') => {
    const productSchemaKeyForIntervention = KongInterventionsComponentsMapper?.[featureKey]?.product?.[type] || null;
    if (productSchemaKeyForIntervention) {
      const schemaObject = schema[productSchemaKeyForIntervention];
      if (schemaObject) {
        return schemaObject;
      }
    }
    return null;
  };

  /*
    Get the default product. i.e Always return the default value from product?.default key id
  */
  const getDefaultProductIdentifierForCombination = useMemo(() => {
    const schemaObject = getQuantitySchemaByProductType('default');
    if (schemaObject) {
      return getQuantityDefaultValue(schemaObject);
    }
    return null;
  }, [quantities.updated]);

  /*
    Get the selected product. i.e Always return the actual value from product?.default key id
  */
  const getSelectedProductForCombination = useMemo(() => {
    const schemaObject = getQuantitySchemaByProductType('default');
    if (schemaObject) {
      return getQuantityActualValue(schemaObject);
    }
    return null;
  }, [quantities.updated]);

  const productIdentifiers = useMemo(() => {
    if (projectInterventionMappingResponse) {
      const productsFromResponse = projectInterventionMappingResponse?.data?.data?.data[0]?.shortlistedProducts || [];
      return Array.from(new Set([getDefaultProductIdentifierForCombination, ...productsFromResponse]));
    }
    return [];
  }, [projectInterventionMappingResponse, getDefaultProductIdentifierForCombination]);

  const updateSelectedProduct = (product) => {
    if (featureKey && product?.identifier) {
      const schemaProductKey = KongInterventionsComponentsMapper[featureKey]?.product?.selected || null;
      if (schemaProductKey) {
        const schemaObject = schema[schemaProductKey];
        if (schemaObject) {
          updateQuantityValue(schemaObject, product?.identifier);
          dispatch({ type: 'update-sandbox-state' });
        }
      }
    }
  };

  const removeSelectedProduct = async (product) => {
    if (featureKey && product) {
      const schemaProductKey = KongInterventionsComponentsMapper[featureKey]?.productIdentifier || null;
      if (schemaProductKey) {
        const schemaObject = schema[schemaProductKey];
        if (schemaObject) {
          updateQuantityValue(schemaObject, null);
        }
      }
    }
    await axios.put(`/ProjectInterventionMapping/intervention/${params?.iid}?key=removeSelectedProduct`);
  };

  const contextProvider = {
    intervention,
    schema,
    state: quantities.updated,
    product: {
      productIdentifiers,
      default: getDefaultProductIdentifierForCombination,
      selected: getSelectedProductForCombination,
    },
    key: featureKey,
    get: {
      getQuantityValue,
      getQuantityDefaultValue,
      getInitialQuantityState: () => quantities.initial,
      getQuantitiesByTypeDefinition,
      getQuantityLabel,
      getQuantityUnit,
      getShortlistedProductsForCombination,
      getCategorySubCategoryAndFilterForCombination,
      getMockQuantityValue,
      getProjectInterventionMapping,
    },
    set: {
      updateQuantityValue,
      updateSandbox: triggerSandoxUpdate,
      resetQuantityValues,
      updateSelectedProduct,
      removeSelectedProduct,
    },
    request: {
      isError: isErrorFetchingInterventionDetail || isErrorInterventionFromMetafire,
      isFetching: isFetchingInterventionDetail || isFetchingInterventionFromMetafire,
    },
    meta: {
      allSandboxes,
      quantities: allmetaquantities,

      // BasicInterventionDetails
      customizable: getValuesFromQuantityDetails('customizable'),
      mandateGenerals: getValuesFromQuantityDetails('Generals'),
      projectinputs: getValuesFromQuantityDetails('project-input'),
      boundaryMin: getValuesFromQuantityDetails('BoundaryMin'),
      boundaryMax: getValuesFromQuantityDetails('BoundaryMax'),
      mandates: getValuesFromQuantityDetails('Mandate'),

      // InterventionDetailsSummary
      capexTotalValue: getValuesFromQuantityDetails('_TotalCapex') || 0,
      capexTotalConvertedValue: getValuesFromQuantityDetails('_TotalCapexConverted') || 0,
      customizable: getValuesFromQuantityDetails('customizable'),
      mandateSummary: getValuesFromQuantityDetails('Summary'),
      result1: getValuesFromQuantityDetails('result-financial'),
      result2: getValuesFromQuantityDetails('result-resource'),
      // sdgText: getValuesFromQuantityDetails('SDG Text', 'label'),
      sdgText: getValuesFromQuantityDetails('SdgText'),
      sdgNumbers: getValuesFromQuantityDetails('SDG Number') || { value: [7] },
      capexConvertedTag: getValuesFromQuantityDetails('CapexConverted'), // TODO: Not needed
      capexConverted:
        allmetaquantities.filter((item) => item && item.tags.includes('CapexConverted')).filter((item) => item && item.tags.includes('Intervention')) || [],

      // AdvancedInterventionDetails
      projectCustomizable: getValuesFromQuantityDetails('project-customizable'),
      interventionCustomizable: getValuesFromQuantityDetails('intervention-customizable'),
      multiplierSource: getValuesFromQuantityDetails('multiplier-source'),
      capexMultiplier: getValuesFromQuantityDetails('capex-multiplier'),
      VendorcapexMultiplier: getValuesFromQuantityDetails('vendor-capex-multiplier'),
      constant: getValuesFromQuantityDetails('constant'),
    },
  };

  return <InterventionDetailsContextData.Provider value={contextProvider}>{children}</InterventionDetailsContextData.Provider>;
};

export default InterventionDetailsContext;

// Temp
// const metafireSchemaMap = InterventionQuantityKeysSchema['hwmApartment'];
// const parameterKey = (key, type) => {
//   switch (type) {
//     case 'w':
//       return `${kongInterventionSchemaKey}__pi_${key}`;
//     case 'r':
//       return `${kongInterventionSchemaKey}_${key}`;
//     default:
//       return key;
//   }
// };

// const metafireSchema = Object.entries(metafireSchemaMap).reduce((memo, [type, objectDetail]) => {
//   Object.entries(objectDetail).map(([objKey, objValue]) => {
//     const memoKey = parameterKey(objKey, type);
//     memo[memoKey] = {
//       ...objValue,
//       rw: type,
//       id: memoKey,
//     };
//   }, {});
//   return memo;
// }, {});
