// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';
import CustomDropdown from '../common/CustomDropdown';

const SPVPenthouseCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const handlePanelType = (type) => {
    updateQuantityValue(schema.spvPenthouse__pi_panelType, type);
    updateQuantityValue(schema.spvPenthouse__pi_selectedProductIdentifier,null)
  };
  const handleInverterType = (type) => {
    updateQuantityValue(schema.spvPenthouse__pi_inverterType, type);
    updateQuantityValue(schema.spvPenthouse__pi_selectedProductIdentifier,null)
  };
  const panelType = getQuantityValue(schema.spvPenthouse__pi_panelType);
  const inverterType = getQuantityValue(schema.spvPenthouse__pi_inverterType);
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <>
        <Grid item xs={4} container alignItems="flex-start" direction="column">
          <Grid xs>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.spvPenthouse__pi_capacity)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.spvPenthouse__pi_capacity)}
                placeholder=""
                onChange={(e) => updateQuantityValue(schema.spvPenthouse__pi_capacity, e.target.value)}
                fullWidth
                onClick={() => updateQuantityValue(schema.spvPenthouse__pi_capacity, null)}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.spvPenthouse__pi_capacity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '2%' }}>
          <Grid xs>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.spvPenthouse__pi_orientation)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <CustomDropdown
                value={getQuantityValue(schema.spvPenthouse__pi_orientation)}
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.spvPenthouse__pi_orientation, e.target.value);
                }}
                options={['South', 'East-West', 'East', 'West', 'North']}
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.spvPenthouse__pi_panelType)}</Typography>
            <Typography className={classes.textHeader3}>
              {panelType === 'Mono PERC' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handlePanelType('Mono PERC')} />}
              {panelType !== 'Mono PERC' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handlePanelType('Mono PERC')} />}
              Mono PERC
            </Typography>
            <Typography className={classes.textHeader3}>
              {panelType === 'Mono PERC Bifacial' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handlePanelType('Mono PERC Bifacial')} />
              )}
              {panelType !== 'Mono PERC Bifacial' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handlePanelType('Mono PERC Bifacial')} />
              )}
              Mono PERC Bifacial
            </Typography>
            <Typography className={classes.textHeader3}>
              {panelType === 'Monocrystalline' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handlePanelType('Monocrystalline')} />
              )}
              {panelType !== 'Monocrystalline' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handlePanelType('Monocrystalline')} />
              )}
              Monocrystalline
            </Typography>
            <Typography className={classes.textHeader3}>
              {panelType === 'Monocrystalline Bifacial' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handlePanelType('Monocrystalline Bifacial')} />
              )}
              {panelType !== 'Monocrystalline Bifacial' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handlePanelType('Monocrystalline Bifacial')} />
              )}
              Monocrystalline Bifacial
            </Typography>
            <Typography className={classes.textHeader3}>
              {panelType === 'Polycrystalline' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handlePanelType('Polycrystalline')} />
              )}
              {panelType !== 'Polycrystalline' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handlePanelType('Polycrystalline')} />
              )}
              Polycrystalline
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.spvPenthouse__pi_inverterType)}</Typography>
            <Typography className={classes.textHeader3}>
              {inverterType === 'Micro Inverter' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleInverterType('Micro Inverter')} />
              )}
              {inverterType !== 'Micro Inverter' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleInverterType('Micro Inverter')} />
              )}
              Micro Inverter
            </Typography>
            <Typography className={classes.textHeader3}>
              {inverterType === 'String Inverter' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleInverterType('String Inverter')} />
              )}
              {inverterType !== 'String Inverter' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleInverterType('String Inverter')} />
              )}
              String Inverter
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '4%' }}>
            <Typography className={classes.textHeader3}>{getQuantityLabel(schema.spvPenthouse_areaRequired)}</Typography>
            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(spvPenthouseareaRequired?.value))} ${spvPenthouseareaRequired?.unit}`}</Typography> */}
            <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
              {getQuantityValue(schema.spvPenthouse_areaRequired)} {getQuantityUnit(schema.spvPenthouse_areaRequired)}
            </Typography>
          </Grid>
        </Grid>
      </>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};
export default SPVPenthouseCustomBasicParameters;
