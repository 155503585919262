import { LegalQuantityTypes } from '../utils/types';

export const useAppMetafireUtilities = () => {
  const defaultRoundOff = 2;

  const getUiRoundTag = (data = {}) => {
    return data?.tags?.find((tag) => tag.startsWith('uiRound:'));
  };

  const getDecimalPlacesByTag = (roundTag) => {
    if (roundTag) {
      const parts = roundTag.split(':');
      return parseInt(parts[1]);
    }
    return null;
  };

  const getRoundOffValue = (value, roundOff = defaultRoundOff) => {
    return isNaN(parseFloat(value)) || String(value).endsWith('.') || /^0+(\.0+)?$/.test(value)
      ? value
      : parseFloat(parseFloat(value).toFixed(roundOff ?? defaultRoundOff));
  };

  const getValue = (data = {}, roundOff = defaultRoundOff) => {
    if (data && Object.keys(data).length !== 0) {
      const roundOffDecimalTag = getUiRoundTag(data);
      const roundOffDecimalValue = getDecimalPlacesByTag(roundOffDecimalTag) ?? roundOff;

      switch (data?.type) {
        case LegalQuantityTypes.Number:
          // A temporary implementation of roundOffs
          return getRoundOffValue(data?.value, roundOffDecimalValue);
        // return isNaN(parseFloat(data?.value)) ? data?.value : parseFloat(data?.value);
        case LegalQuantityTypes.UserActualDefault:
          const selectedValueForUse = data?.value?.user ?? data?.value?.default;
          // A temporary implementation of roundOffs
          return getRoundOffValue(selectedValueForUse, roundOffDecimalValue);
        case LegalQuantityTypes.String:
          return data?.value ? String(data?.value) : data?.value;
        case LegalQuantityTypes.Boolean:
          return data?.value;
        case LegalQuantityTypes.Object:
          return data?.value;
        default:
          // return data?.value
          // A temporary implementation of roundOffs
          return getRoundOffValue(data?.value, roundOffDecimalValue);
      }
    }
    return '-';
  };

  const getLabel = (data = {}) => {
    if (data && data?.label) {
      return data?.label;
    }
    return '-';
  };

  const getUnit = (data = {}) => {
    if (data && data?.unit) {
      return data?.unit;
    }
    return '-';
  };

  return {
    getValue,
    getLabel,
    getUnit,
    getRoundOffValue,
    utils: {
      getDecimalPlacesByTag,
      getUiRoundTag,
      defaultRoundOff,
    },
  };
};
