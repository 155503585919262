import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../common/button/Button';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';

import { useParams } from 'react-router';
import Header from '../../common/header/Header';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { GOALS_CATEGORIES } from './styles';
import numberWithCommas from '../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../metafire/hooks/useAppMetafireUtilities';
import CasaSkeleton from '../../common/skeleton/CasaSkeleton';
import GoalCategoryCard from './GoalCategoryCard';
import GoalCard from './GoalCard';
import axios from 'axios';
import { useAppApiRequest } from '../../../hooks/useAppApiRequest';
import ApiError from '../../common/Error/ApiError';
import { getGoal, getTarget } from './utils/util';

const useStyles = makeStyles((theme) => ({
  main: { paddingLeft: '4%', paddingRight: '4%', paddingTop: '9%' },
}));

const mockGoalsResponse = {
  data: {
    success: true,
    data: {
      version: 'v1',
      data: [
        {
          id: 'goals_overall',
          rw: 'r',
          value: {
            goalsAchieved: {
              achievedGoals: 0,
              totalGoals: 4,
              progress: 0,
            },
            targetsAchieved: {
              achievedGoals: 1,
              totalGoals: 4,
              progress: 0,
            },
            capexIncrease: 38.806533541920416,
            capexIncreaseUnit: 'Rs/Sqft',
          },
          label: null,
          tags: [],
          type: 'object',
          unit: null,
        },
        {
          id: 'goals_energy',
          rw: 'r',
          value: {
            name: 'Net Zero Energy',
            description:
              'Net Zero Energy buildings generate as much energy as they consume, resulting in a net-zero balance between energy production and consumption.',
            additionalCostPerSqft: 44.230944531098714,
            additionalCostPerSqftUnit: 'RS/Sqft',
            progress: 36.49665690599361,
            status: 'In Progress',
            targets: [
              {
                name: 'Maximize Onsite Renewable Energy',
                description: '',
                costPerSqft: null,
                costPerSqftUnit: null,
                progress: 1,
                status: 'In Progress',
                tier: 'Tier I',
                state: true,
                achievability: 'Yes',
                maxContributionPercent: null,
                additionalCostPerUnitArea: 0,
                additionalCost: 0,
                maxPotentialPercent: null,
                maxRenewableEnergySavings: 441733.0302000001,
              },
              {
                name: 'Common Area Energy Neutral',
                description: null,
                costPerSqft: null,
                costPerSqftUnit: null,
                progress: null,
                status: 'In Progress',
                tier: 'Tier I',
                state: true,
                achievability: 'Yes',
                maxContributionPercent: null,
                additionalCostPerUnitArea: 0,
                additionalCost: 0,
                maxPotentialPercent: null,
              },
            ],
          },
          label: null,
          tags: [],
          type: 'object',
          unit: null,
        },
        {
          id: 'goals_waste',
          rw: 'r',
          value: {
            name: 'Net Zero Waste',
            description:
              'Net Zero Waste in a building aims to reduce waste generation, increase recycling, and minimize landfill contributions, ultimately striving for a net-zero impact on waste disposal.',
            additionalCostPerSqft: 0.016823111170220794,
            additionalCostPerSqftUnit: 'RS/Sqft',
            progress: 82.59733333333332,
            status: 'In Progress',
            targets: [],
          },
          label: null,
          tags: [],
          type: 'object',
          unit: null,
        },
        {
          id: 'goals_water',
          rw: 'r',
          value: {
            name: 'Net Zero Water',
            description:
              'Net Zero Water for a building involves strategies to minimize water usage and maximize reuse, aiming to balance water consumption with an equal return to the local watershed or aquifer, ultimately achieving a net-zero impact on water resources.',
            additionalCostPerSqft: -5.441234100348518,
            additionalCostPerSqftUnit: 'RS/Sqft',
            progress: 9.698187596727124,
            status: 'In Progress',
            targets: [
              {
                name: 'Complete Smart Water Metering',
                description: null,
                costPerSqft: null,
                costPerSqftUnit: null,
                progress: 100,
                status: 'Achieved',
                tier: 'Tier I',
                state: true,
                achievability: 'Yes',
                maxContributionPercent: 3.939952466873958,
                additionalCostPerUnitArea: 0,
                additionalCost: 0,
              },
            ],
          },
          label: null,
          tags: [],
          type: 'object',
          unit: null,
        },
        {
          id: 'goals_embodiedCarbon',
          rw: 'r',
          value: {
            name: 'Net Zero Embodied Carbon',
            description:
              "Net Zero Embodied Carbon in construction minimizes carbon emissions from building materials and processes, aiming for a net-zero impact on the building's carbon footprint.",
            additionalCostPerSqft: 0,
            additionalCostPerSqftUnit: 'RS/Sqft',
            progress: 0,
            status: 'In Progress',
            targets: [
              {
                name: 'Core & Shell Alternative Materials',
                description: null,
                costPerSqft: null,
                costPerSqftUnit: null,
                progress: null,
                status: 'In Progress',
                tier: 'Tier I',
                state: true,
                achievability: 'No',
                maxContributionPercent: null,
                additionalCostPerUnitArea: 0,
                additionalCost: 0,
                maxContributionPercentage: null,
                maxMaterialSavings: 0,
              },
            ],
          },
          label: null,
          tags: [],
          type: 'object',
          unit: null,
        },
      ],
    },
  },
};

const CreateGoals = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let { id } = useParams();
  const { getRoundOffValue } = useAppMetafireUtilities();
  const [allGoals, setAllGoals] = useState([]);
  const { data: goalResponse, isFetching, isError } = useAppApiRequest(`/goalsschemev2`);
  const {
    data: projectTargetResponse,
    isFetching: isTargetFetching,
    isError: isTargetError,
    refetch: targetFetch,
  } = useAppApiRequest(`/projectTarget?project=${id}`);
  const goalsdata = goalResponse?.data?.data?.data;
  const targetMapping = projectTargetResponse?.data?.data?.data;

  useEffect(() => {
    const response = mockGoalsResponse;
    if (response?.data) {
      const allGoals = response?.data?.data?.data?.filter((data) => data.id !== 'goals_overall');
      setAllGoals(allGoals);
    }
  }, []);

  const handleStatusUpdate = (checked, target) => {
    axios
      .put(`/projectTarget/${target?._id}`, { status: checked, projectId: id })
      .then((res) => {
        // Trigger refetch after successful update
        targetFetch();
      })
      .catch((error) => {
        console.error('Error updating target:', error);
      });
  };

  if (isError)
    return (
      <div className={classes.main}>
        <ApiError />
      </div>
    );

  if (isTargetError)
    return (
      <div className={classes.main}>
        <ApiError />
      </div>
    );

  //the material target 'Core & Shell Alternative Materials' - is linked to concrete intervention. Which has not developed yet
  //because the intervention has not developed yet - the target can not be created with out that.
  //product team does not want two category rows to be empty as waste is already empty. So applied the hack remove it once the target is present

  return (
    <>
      <Header />
      <Grid style={{ paddingLeft: '10%', paddingRight: '10%', paddingTop: '8%' }} container justifyContent="center" alignItems="center" xs={12}>
        <Grid xs={12} style={{ paddingBottom: '3%', marginLeft: '-3%' }}>
          <CommonHeadingWithIcon heading=" Almost There" variant="h3" />
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12} style={{ paddingRight: '10%' }}>
          <Typography style={{ textAlign: 'justify', lineHeight: 2 }}>
            One last step! Research shows that setting goals and targets upfront improves adherence by 90%. Based on the details of the project you shared, we
            have identified Net Zero goals and applicable targets. Review and customize your target selection if you would like.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h5">
            <b>Goals</b>
          </Typography>
        </Grid>

        {/* DONE */}
        <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start">
          {isFetching ? (
            <CasaSkeleton variant="rect" height="200px" width="98%" animation="wave" />
          ) : (
            allGoals &&
            allGoals?.map((goalCategory, index) => {
              return (
                <Grid container xs={3} key={index}>
                  <GoalCategoryCard
                    border={`2px solid ${GOALS_CATEGORIES[goalCategory?.id].border}`}
                    background={GOALS_CATEGORIES[goalCategory?.id].background}
                    icon={GOALS_CATEGORIES[goalCategory?.id].icon}
                    title={goalCategory?.value?.name}
                    text={`${numberWithCommas(getRoundOffValue(goalCategory?.value?.additionalCostPerSqft))} ${goalCategory?.value?.additionalCostPerSqftUnit}`}
                    barBackground={GOALS_CATEGORIES[goalCategory?.id].bar}
                    progressValue={goalCategory?.value?.progress}
                    barText={numberWithCommas(getRoundOffValue(goalCategory?.value?.progress))}
                    showProgress={false}
                    showValue={false}
                    description={getGoal(goalsdata, goalCategory?.value?.name)?.description}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
        <Grid xs={12} style={{ marginTop: '3%' }}>
          <Typography variant="h5">
            <b>Achievable Targets</b>
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12} style={{ marginBottom: '3%', paddingRight: '10%' }}>
          <Typography style={{ textAlign: 'justify', lineHeight: 2 }} variant="h6">
            Here are targets categorized to align with your Net Zero goals. You can toggle each target based on preference and project needs. Turning off a
            target disables associated interventions unless shared by other active targets. Targets are classified into Tiers I to IV, indicating impact levels
            on Net Zero goals up to 25%, 50%, 75%, and 100% respectively.
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start" alignItems="flex-start" xs={12} style={{ marginBottom: '5%' }}>
          {isTargetFetching ? (
            <div style={{ marginTop: 16, width: '100%' }}>
              <CasaSkeleton variant="rect" height="100px" width="98%" animation="wave" />
            </div>
          ) : (
            allGoals?.map((goal, index) => {
              return (
                <Grid key={index} item xs={3} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item container alignItems="center" justifyContent="center" style={{ marginBottom: '3%' }}>
                    <Grid xs={3} container alignItems="center" justifyContent="center">
                      <img src={GOALS_CATEGORIES[goal?.id].targets.icon} alt="icon" />
                    </Grid>
                    <Grid xs={9} container alignItems="center" justifyContent="flex-start">
                      <Typography variant="h5">{GOALS_CATEGORIES[goal?.id].targets.title}</Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: '3%' }}>
                    {goal?.value?.targets.map(({ achievability, name, tier, additionalCostPerUnitArea, progress, state }) => {
                      if (achievability === 'No') {
                        return <GoalCard.NotAchievable border="2px solid #CCCCCC" title={name} />;
                      } else if (achievability === 'Yes' && getTarget(targetMapping, name)?.enabled !== true) {
                        return (
                          <GoalCard.Disabled
                            border={`2px solid ${GOALS_CATEGORIES[goal?.id].targets.border}`}
                            title={name}
                            tier={tier}
                            text={`+${numberWithCommas(getRoundOffValue(additionalCostPerUnitArea))} Rs/Sqft`}
                            progressValue={progress}
                            barBackground={GOALS_CATEGORIES[goal?.id].targets.bar}
                            barText={numberWithCommas(getRoundOffValue(progress))}
                            showProgress={false}
                            showValue={false}
                            status={getTarget(targetMapping, name)?.enabled}
                            target={getTarget(targetMapping, name)}
                            handleStatusUpdate={handleStatusUpdate}
                          />
                        );
                      } else {
                        return (
                          <GoalCard.Default
                            border={`2px solid ${GOALS_CATEGORIES[goal?.id].targets.border}`}
                            title={name}
                            tier={tier}
                            text={`+${numberWithCommas(getRoundOffValue(additionalCostPerUnitArea))} Rs/Sqft`}
                            progressValue={progress}
                            barBackground={GOALS_CATEGORIES[goal?.id].targets.bar}
                            barText={numberWithCommas(getRoundOffValue(progress))}
                            showProgress={false}
                            showValue={false}
                            status={getTarget(targetMapping, name)?.enabled}
                            target={getTarget(targetMapping, name)}
                            handleStatusUpdate={handleStatusUpdate}
                          />
                        );
                      }
                    })}
                  </Grid>
                </Grid>
              );
            })
          )}
        </Grid>
        <Grid container item xs={12} alignItems="center" style={{ marginBottom: '5%' }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <PrimaryButton
              children="Save and Exit"
              style={{ width: '150px', borderRadius: '40px', height: '30px' }}
              onClick={() => history.push(`/projects/${id}/insights`)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateGoals;
