import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import Fields from '../../../common/textfield/Fields';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
import MaterialPresetBrand from './components/MaterialsPresetBrand';
import MaterialPresetPaint from './components/MaterialsPresetPaint';
import MaterialPresetFinish from './components/MaterialsPresetFinish';
import { TooltipData, ParentPage, ChildPage, ComponentName, ComponentTooltipData } from '../../../../staticData/TooltipStaticData';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';
import { SomethingChanged } from '../EditProject';
import InteriorPaints from './components/InteriorPaints';
import IndoorFlooring from './components/IndoorFlooring';
import RooftopMaterialDetails from './components/RooftopMaterialDetails';
import PrimaryButton from '../../../common/button/Button';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import ApiError from '../../../common/Error/ApiError';

const MaterialDetails = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const [value, setValue] = React.useState(undefined);
  // for exterior
  const [paintBrands, setPaintBrands] = useState('');
  const [paintFinishes, setPaintFinishes] = useState('');
  const [paintNames, setPaintNames] = useState('');

  // for interior
  const [interiorPaintBrands, setInteriorPaintBrands] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  const { data: projectData } = useAppProjectDetails(id);
  const {
    get: { form, projectPIP, isError },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const { data } = useAppPIPScheme(type);
  const manualPips =
    data &&
    data?.filter(
      (d) =>
        d.name === 'project__pi_exteriorPaintManualBrandName' ||
        d.name === 'project__pi_exteriorPaintManualPaintName' ||
        d.name === 'project__pi_exteriorPaintManualCoverageArea' ||
        d.name === 'project__pi_exteriorPaintManualEC' ||
        d.name === 'project__pi_exteriorPaintManualCapex'
    );
  const presetPips =
    data &&
    data
      ?.filter(
        (d) =>
          d.name === 'project__pi_exteriorPaintWaterBasedBrandName' ||
          d.name === 'project__pi_exteriorPaintWaterBasedFinish' ||
          d.name === 'project__pi_exteriorPaintWaterBasedPaintName' ||
          d.name === 'project__pi_exteriorPaintManualCoverageArea' ||
          d.name === 'project__pi_exteriorPaintManualEC' ||
          d.name === 'project__pi_exteriorPaintManualCapex'
      )
      .sort((a, b) => (a.name === 'project__pi_exteriorPaintWaterBasedBrandName' ? -1 : a.name === 'project__pi_exteriorPaintWaterBasedPaintName' ? -1 : 0));

  const indoorFlooringPips =
    data &&
    data?.filter(
      (d) =>
        d.name === 'project__pi_indoorFlooringLivingAndDining' ||
        d.name === 'project__pi_indoorFlooringKitchen' ||
        d.name === 'project__pi_indoorFlooringBedroom' ||
        d.name === 'project__pi_indoorFlooringBathroom' ||
        d.name === 'project__pi_indoorFlooringUtilityAndBalcony'
    );

  const rooftopMaterialPips =
    data &&
    data?.filter((d) =>
      [
        'project__pi_cementTilesRoof',
        'project__pi_roofGardenThin',
        'project__pi_roofGardenMedium',
        'project__pi_roofGardenThick',
        'project__pi_roofGardenHeavy',
      ].includes(d.name)
    );
  const typeOfConcretePIP = data && data?.find((d) => d.name === 'project__pi_typeOfConcrete');
  const exteriorPaintUserChoicePIP = data && data?.find((d) => d.name === 'project__pi_exteriorPaintUserChoice');
  const interiorPaintUserChoicePIP = data && data?.find((d) => d.name === 'project__pi_interiorPaintUserChoice');

  const presetBrandUserChoicePIP = data && data?.find((d) => d.name === 'project__pi_exteriorPaintWaterBasedBrandName');
  const presetFinishUserChoicePIP = data && data?.find((d) => d.name === 'project__pi_exteriorPaintWaterBasedFinish');
  const presetPaintUserChoicePIP = data && data?.find((d) => d.name === 'project__pi_exteriorPaintWaterBasedPaintName');

  const selectedPaintBrand = form?.pipvalues[presetBrandUserChoicePIP?._id];
  const selectedPaintFinish = form?.pipvalues[presetFinishUserChoicePIP?._id];
  const selectedPaintName = form?.pipvalues[presetPaintUserChoicePIP?._id];

  const {
    // selectedPaintBrand,
    // selectedPaintFinish,
    // selectedPaintName,
    exteriorPaintUserChoicePIPValue,
    interiorPaintUserChoicePIPValue,
    // solventEnabled,
    // waterBasedEnabled,
    // manualEnabled,
  } = useMemo(() => {
    if (projectPIP) {
      const presetType = projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintUserChoice');
      const presetBrand = projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintWaterBasedBrandName');
      const presetFinish = projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintWaterBasedFinish');
      const presetName = projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintWaterBasedPaintName');
      // const presetTypeValue = presetType?.value;
      const presetBrandValue = presetBrand?.value;
      const presetFinishValue = presetFinish?.value;
      const presetNameValue = presetName?.value;

      const exteriorPaintUserChoice = projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintUserChoice');
      const interiorPaintUserChoice = projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_interiorPaintUserChoice');

      return {
        selectedPaintBrand: presetBrandValue,
        selectedPaintFinish: presetFinishValue,
        selectedPaintName: presetNameValue,
        exteriorPaintUserChoicePIPValue: exteriorPaintUserChoice?.value,
        interiorPaintUserChoicePIPValue: interiorPaintUserChoice?.value,
        // solventEnabled: presetTypeValue === 'Solvent-Based Paints',
        // waterBasedEnabled: presetTypeValue === 'Use Paints from popular brands',
        // manualEnabled: presetTypeValue === 'Manual',
      };
    }
    return {};
  }, [projectPIP]);

  useEffect(() => {
    axios.get(`/PaintProvider?list=Brand&type=Exterior Paints`).then((res) => {
      setPaintBrands(res && res.data.data.data);
      axios.get(`/PaintProvider?list=Brand&type=Interior Paints`).then((res) => setInteriorPaintBrands(res && res.data.data.data));
    });
  }, []);

  useEffect(() => {
    if (selectedPaintBrand) {
      axios.get(`PaintProvider?list=Finish&type=Exterior Paints&brand=${selectedPaintBrand}`).then((res) => setPaintFinishes(res && res.data.data.data));
    }
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish) {
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Exterior Paints`)
        .then((res) => setPaintNames(res && res.data.data.data));
    }
  }, [selectedPaintBrand, selectedPaintFinish]);

  useEffect(() => {
    if (projectPIP) {
      projectPIP.forEach((d) => {
        handlePIPValueUpdate(d.PIPScheme._id, d.value);
      });
    }
  }, [projectPIP]);

  // useEffect(() => {
  //   if (projectPIP.length !== 0 && myArray.length !== 0) {
  //     myArray.forEach((ele) => {
  //       let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
  //       if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
  //     });
  //   }
  // }, [projectPIP, myArray]);

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else history.push(`/projects/${id}/edit?type=${next}`);
    }
  };

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects`);
    history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSave = async (next) => {
    if (next === 'file') {
      axios
        .put(`/ProjectPIPMapping`, {
          projectId: id,
          values: Object.entries(form?.pipvalues).map(([key, value]) => ({
            PIPScheme: key,
            value,
          })),
        })
        .then((response) => {
          swal({ icon: 'success', title: 'Data Saved' });
          props.setFalseAfterSomethingChanged();
          handlSetSomethingChanged(false);
          history.push(`/projects/${id}/edit?type=${next}`);
        });
    }
  };

  // useEffect(() => {
  //   if (solventEnabled) setValue('preset1');
  //   if (waterBasedEnabled) setValue('preset2');
  //   if (manualEnabled) setValue('other');
  // }, [solventEnabled, waterBasedEnabled, manualEnabled]);

  const filterFromData = (tooltipData, ParentPage, ChildPage, ComponentName) => {
    const result = tooltipData && tooltipData.find((d) => d.parentPage === ParentPage && d.childPage === ChildPage && d.componentName === ComponentName);
    return result && result.content;
  };

  const ExteriorPaintsTooltipData = filterFromData(
    TooltipData,
    ParentPage.INTERVENTIONS,
    ChildPage.INTERVENTIONS_SUMMARY,
    ComponentName.EXTERIOR_PAINTS,
    ComponentTooltipData.EXTERIOR_PAINTS
  );

  const handleError = (error) => swal({ icon: 'error', title: error });

  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );

  const exteriorPaintUserChoiceValue = form?.pipvalues[exteriorPaintUserChoicePIP?._id];
  const interiorPaintUserChoiceValue = form?.pipvalues[interiorPaintUserChoicePIP?._id];
  return (
    <>
      <Grid container justifyContent="flex-start" direction="column" item xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {data && data ? (
        <>
          {/* exterior implementation */}
          <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
              <Typography variant="h5">Exterior Paint</Typography>
              <CustomizedTooltips
                marginTop="15px"
                icon="info"
                title={ExteriorPaintsTooltipData.map((v) => (
                  <Typography>{v.data}</Typography>
                ))}
                placement="right"
              />
            </Grid>
            <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="exterior paint"
                  name="paint schemes"
                  value={exteriorPaintUserChoiceValue || ''}
                  onChange={(e) => handlePIPValueUpdate(exteriorPaintUserChoicePIP?._id, e.target.value)}
                >
                  {exteriorPaintUserChoicePIP.options.map((option, index) => {
                    return (
                      <>
                        <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />
                        {exteriorPaintUserChoiceValue &&
                          index === 1 &&
                          exteriorPaintUserChoiceValue === 'Water Based' &&
                          presetPips &&
                          presetPips.map((pip) => {
                            if (pip.name === 'project__pi_exteriorPaintWaterBasedBrandName') {
                              return (
                                <>
                                  <MaterialPresetBrand
                                    pip={pip && pip}
                                    selectedPaintBrand={selectedPaintBrand}
                                    paintBrands={paintBrands && paintBrands}
                                    appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
                                    pipValues={form?.pipvalues}
                                  />
                                </>
                              );
                            }
                            if (pip.name === 'project__pi_exteriorPaintWaterBasedPaintName') {
                              return (
                                <>
                                  <MaterialPresetPaint
                                    // value={value && value}
                                    pip={pip && pip}
                                    selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                                    selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
                                    paintNames={paintNames && paintNames}
                                    selectedPaintName={selectedPaintName && selectedPaintName}
                                    appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
                                  />
                                </>
                              );
                            }
                            if (pip.name === 'project__pi_exteriorPaintWaterBasedFinish') {
                              return (
                                <>
                                  <MaterialPresetFinish
                                    // value={value && value}
                                    pip={pip && pip}
                                    selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                                    selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
                                    paintFinishes={paintFinishes && paintFinishes}
                                    appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
                                  />
                                </>
                              );
                            }
                          })}
                        {exteriorPaintUserChoiceValue &&
                          index === 2 &&
                          exteriorPaintUserChoiceValue === 'Manual' &&
                          manualPips &&
                          manualPips.map((pip) => {
                            return (
                              <div style={{ marginTop: '4%' }}>
                                <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '2%' }}>
                                  <Typography variant="h6">{pip.displayName}</Typography>
                                </Grid>
                                <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
                                  <Fields
                                    placeholder="Enter name"
                                    value={form?.pipvalues[pip._id]}
                                    onChange={(e) => handlePIPValueUpdate(pip._id, e.target.value)}
                                    onClick={(e) => e.target.select()}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                              </div>
                            );
                          })}
                      </>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {/* TODO: Commented for now
          <>
            <InteriorPaints
              data={data && data}
              interiorPaintBrands={interiorPaintBrands && interiorPaintBrands}
              interiorPaintFinish={interiorPaintBrands && interiorPaintBrands}
              interiorPaintNames={interiorPaintBrands && interiorPaintBrands}
              appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
              projectPIP={projectPIP && projectPIP}
              pipValues={form?.pipvalues}
              interiorPaintUserChoicePIP={interiorPaintUserChoicePIP && interiorPaintUserChoicePIP}
              interiorPaintUserChoicePIPValue={interiorPaintUserChoiceValue}
              manualPips={manualPips && manualPips}
            />

            <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
              <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
                <Typography variant="h5">{typeOfConcretePIP && typeOfConcretePIP.displayName}</Typography>
              </Grid>
              <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="exterior paint"
                    name="paint schemes"
                    value={form.pipvalues[typeOfConcretePIP._id] || ''}
                    onChange={(e) => handlePIPValueUpdate(typeOfConcretePIP._id, e.target.value)}
                  >
                    {typeOfConcretePIP.options.map((option, index) => {
                      return <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />;
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <IndoorFlooring
              data={indoorFlooringPips && indoorFlooringPips}
              appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
              pipValues={form?.pipvalues}
              projectPIP={projectPIP && projectPIP}
            />

            <RooftopMaterialDetails
              data={rooftopMaterialPips && rooftopMaterialPips}
              appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
              projectPIP={projectPIP && projectPIP}
            />
          </> */}
        </>
      ) : null}

      <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '6%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};

export default MaterialDetails;
